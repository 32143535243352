// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-busy-anglia-js": () => import("./../../../src/pages/busy-anglia.js" /* webpackChunkName: "component---src-pages-busy-anglia-js" */),
  "component---src-pages-cennik-index-js": () => import("./../../../src/pages/cennik/index.js" /* webpackChunkName: "component---src-pages-cennik-index-js" */),
  "component---src-pages-cennik-przeprowadzki-js": () => import("./../../../src/pages/cennik/przeprowadzki.js" /* webpackChunkName: "component---src-pages-cennik-przeprowadzki-js" */),
  "component---src-pages-cennik-transport-na-lawecie-js": () => import("./../../../src/pages/cennik/transport-na-lawecie.js" /* webpackChunkName: "component---src-pages-cennik-transport-na-lawecie-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-paczki-js": () => import("./../../../src/pages/paczki.js" /* webpackChunkName: "component---src-pages-paczki-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-przewoz-zwierzat-js": () => import("./../../../src/pages/przewoz-zwierzat.js" /* webpackChunkName: "component---src-pages-przewoz-zwierzat-js" */)
}

